<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-card-title class="overline primary--text font-weight-medium">
        Send password reset link confirmation
      </v-card-title>
      <v-card-text class="text-center">
        <p>
          Are you sure you want to send the password reset link for this guest?
        </p>
        <p>
          <span class="font-weight-medium secondary--text">{{ username }}</span>
        </p>
        <p>
          The password reset link will be sent to your guest's registered email
          address.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false" depressed :disabled="isSending">
          No
        </v-btn>

        <v-btn @click="send" color="secondary" depressed :loading="isSending">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useAccountStore } from '@/store/account'
import { mapActions } from 'pinia'

export default {
  name: 'SendPasswordResetLink',
  props: {
    username: { type: String },
    sendPasswordResetLinkDialog: { type: Boolean }
  },
  data() {
    return {
      isSending: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.sendPasswordResetLinkDialog
      },
      set(v) {
        this.$emit('update:sendPasswordResetLinkDialog', v)
      }
    }
  },
  methods: {
    async send() {
      this.isSending = true
      await this.sendPasswordResetLinkAsync(this.username).finally(() => {
        this.isSending = false
        this.dialog = false
      })
    },
    ...mapActions(useAccountStore, ['sendPasswordResetLinkAsync'])
  }
}
</script>