<template>
  <v-chip :color="chip.color" outlined small>
    {{ chip.text }}
  </v-chip>
</template>

<script>
import AccountMixin from '@/mixins/account.mixin'

export default {
  name: 'AccountStatusChip',
  mixins: [AccountMixin],
  props: {
    status: { type: String }
  },
  data() {
    return {}
  },
  computed: {
    chip() {
      return _.find(this.oktaUserStatuses, (x) => x.value === this.status)
    }
  }
}
</script>