import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"overline primary--text font-weight-medium"},[_vm._v(" Reset multifactors confirmation ")]),_c(VCardText,{staticClass:"text-center"},[_c('div',[_vm._v(" Are you sure you want to reset multifactors for "),_c('div',[_c('span',{staticClass:"font-weight-medium secondary--text"},[_vm._v(_vm._s(_vm.username))]),_vm._v("?")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","disabled":_vm.isSending},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c(VBtn,{attrs:{"color":"secondary","depressed":"","loading":_vm.isSending},on:{"click":_vm.send}},[_vm._v(" Yes ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }